import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby'

//Actions
import { getDesigner } from '../../actions/getDesigner'
import { getExplorer } from '../../actions/getExplorer'
import { updateCampaign } from '../../actions/updateCampaign'
import { saveLead } from '../../actions/saveLead'

//Packages
import Helmet from 'react-helmet'
import parse from 'url-parse'
import moment from 'moment'

//Components
import NavMinimal from '../../components/nav/navMinimal'
import FooterMinimalStatic from '../../components/nav/footerMinimalStatic'
import CreativeProfile from '../../components/creative/creativeProfile'

//Icons
import { MdDownload } from 'react-icons/md'
import { FaSpinner } from 'react-icons/fa'

//Redux
const mapStateToProps = ({
  designprofile,
  explorerprofile,
  isExplorerTemplatesLoading,
  isLoading,
}) => {
  return {
    designprofile,
    explorerprofile,
    isExplorerTemplatesLoading,
    isLoading,
  }
}
const mapDispatchToProps = (dispatch) => ({
  getDesigner: (tag, displayname) => {
    dispatch(getDesigner(tag, displayname))
  },
  getExplorer: (id) => {
    dispatch(getExplorer(id))
  },
  updateCampaign: (campaignId) => {
    dispatch(updateCampaign(campaignId))
  },
  saveLead: (userData) => {
    dispatch(saveLead(userData))
  },
})

const CreativeDetail = (props) => {
  const {
    getDesigner,
    getExplorer,
    updateCampaign,
    designprofile,
    explorerprofile,
    isExplorerTemplatesLoading,
    isLoading,
    saveLead,
  } = props
  const [templateImage, setTemplateImage] = useState(null)
  const [keyword, setKeyword] = useState(null)

  useEffect(() => {
    //like componentDidMount
    const url = parse(window.location.href, true)

    console.log('profile/index - url')
    console.log(url)

    if (url.query && url.query.c) {
      updateCampaign(url.query.c)
    }

    if (url.pathname) {
      const split = url.pathname.split('/')
      console.log('profile/index - split')
      console.log(split)

      getExplorer(split[2])
      setKeyword(split[2])
    }
  }, [])

  console.log('tag/index - designprofile')
  console.log(designprofile)

  let keywordCapitalized
  if (keyword) {
    keywordCapitalized = keyword.charAt(0).toUpperCase() + keyword.slice(1)
    keywordCapitalized = decodeURIComponent(keywordCapitalized)
  }

  return (
    <>
      <Helmet
        title={`Sidepath - Explorer`}
        meta={[
          {
            name: 'description',
            content: `See your Sidepath guides`,
          },
        ]}
      />
      <NavMinimal />
      <div>
        <div class="container my-4">
          <div class="row">
            <h1 class="h2 px-0 mb-3">Your Sidepath Guides</h1>
          </div>
          {isExplorerTemplatesLoading || isLoading ? (
            <div class="row d-flex flex-row align-items-center justify-content-center">
              <FaSpinner
                size={20}
                className="icon-spin gray-1"
                // style={{ marginLeft: '10px' }}
              />
            </div>
          ) : null}
          {!isExplorerTemplatesLoading &&
          explorerprofile &&
          explorerprofile.length > 0 ? (
            <>
              <div class="row">
                <div class="col-md-8 font-weight-bold p-1">Guide</div>
                <div class="col-md-4 font-weight-bold p-1">Download</div>
              </div>
              {explorerprofile.map((orderItem, i) => (
                <>
                  {orderItem && orderItem.offer
                    ? orderItem.offer.map((offerItem, j) => (
                        <>
                          {offerItem.active === false ? (
                            <></>
                          ) : (
                            <div
                              class={`row fade-in py-2 border-bottom-1-gray-7`}
                            >
                              <div class="col-md-8 p-1 d-flex flex-row align-items-center justify-content-start">
                                {offerItem.title}
                              </div>
                              <div class="col-md-4 p-1">
                                {moment().unix() <=
                                offerItem.datecreated + 604800 ? (
                                  <div class="d-flex flex-row align-items-center justify-content-start">
                                    <a
                                      class="btn btn-outline-primary"
                                      href={`https://${process.env.S3BUCKETGUIDE}.s3.amazonaws.com/${offerItem.key}`}
                                      target="_blank"
                                    >
                                      <MdDownload size={20} />
                                    </a>
                                    <div class="pl-1 gray-1">
                                      {(offerItem.size / 1000000).toFixed(1)} MB
                                    </div>
                                  </div>
                                ) : (
                                  <button
                                    class="btn btn-outline-primary"
                                    onClick={() => {
                                      saveLead({
                                        orderId: orderItem.id,
                                        userName: orderItem.name,
                                        userEmail: orderItem.email,
                                        leadSource: orderItem.leadSource,
                                        offerFolder: orderItem.offerFolder,
                                        offerAttachment:
                                          orderItem.offerAttachment,
                                        offerTitle: orderItem.offerTitle,
                                        mktProfileId: orderItem.mktProfileId,
                                        mode: 'reprovision',
                                      })
                                    }}
                                  >
                                    Get download link
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))
                    : null}
                </>
              ))}
            </>
          ) : (
            <div class="row p-2">No guides available</div>
          )}
        </div>
      </div>
      <FooterMinimalStatic />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreativeDetail)
