import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'

import { amplifyconfig } from '../utils/config'
Amplify.configure(amplifyconfig)

export function getDesigner(context, displayName) {
  let apiName = 'Picovideomkt'

  let path
  let options = {}
  if (context === 'profile') {
    path = 'designer/profile'

    options = {
      body: {
        displayname: displayName,
      },
    }
  } else if (context === 'tag') {
    path = 'templates/search'
    // path = 'curate/search'

    options = {
      body: {
        keyword: displayName,
      },
    }
  } else if (context === 'single') {
    path = 'templates/single'

    options = {
      body: {
        id: displayName,
      },
    }
  }

  console.log('actions - getDesigner - displayName')
  console.log(displayName)
  console.log('actions - getDesigner - options')
  console.log(options)

  return (dispatch) => {
    dispatch({
      type: 'DESIGNER_PROFILE_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.post(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - getDesigner))')
          console.log(data)

          return resolve(
            dispatch({
              type: 'DESIGNER_PROFILE_UPDATE',
              data: data,
            })
          )
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}
