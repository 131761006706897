import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'

import { getExplorer } from './getExplorer'

import { amplifyconfig } from '../utils/config'
Amplify.configure(amplifyconfig)

export function saveLead(userData) {
  let apiName = 'Picovideomkt'
  let path = 'submitlead'

  let options = {
    body: userData,
  }

  return (dispatch) => {
    dispatch({
      type: 'LOADING_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.post(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - saveLead))')
          console.log(data)
          dispatch({
            type: 'LEAD_SUBMITTED',
            data: data,
          })

          dispatch(getExplorer(userData.mktProfileId))
          // return resolve(
          //   setTimeout(() => {
          //     dispatch({
          //       type: 'LEAD_SUBMITTED_RESET',
          //     })
          //   }, 7000)
          // )
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}

export function saveForm(userData) {
  let apiName = 'Picovideomkt'
  let path = 'submitform'

  let options = {
    body: userData,
  }

  return (dispatch) => {
    dispatch({
      type: 'LOADING_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.post(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - saveLead))')
          console.log(data)
          dispatch({
            type: 'FORM_SUBMITTED',
            data: data,
          })

          return resolve()
          // setTimeout(() => {
          //   dispatch({
          //     type: 'LEAD_SUBMITTED_RESET',
          //   })
          // }, 7000)
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}
