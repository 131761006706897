import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'

import { amplifyconfig } from '../utils/config'
Amplify.configure(amplifyconfig)

export function getExplorer(id) {
  let apiName = 'Picovideomkt'

  let path = 'explorer/guides'

  let options = {
    body: {
      id,
    },
  }

  console.log('actions - getExplorer - options')
  console.log(options)

  return (dispatch) => {
    dispatch({
      type: 'EXPLORER_PROFILE_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.post(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - getExplorer))')
          console.log(data)

          return resolve(
            dispatch({
              type: 'EXPLORER_PROFILE_UPDATE',
              data: data,
            })
          )
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}
